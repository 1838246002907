/* 基础配色 */
/* 深色 */
/* 内容区边距 */
/* 查询表单&表格左右内边距 */
/* table悬停及选中颜色 */
/* size="small"的Table表格行高度 */
/* 标题栏活动背景色 */
/* 标题栏高亮背景色 */
/* 标题栏文本高亮颜色 */
/* 标题栏文本颜色 */
.koala-query-form-wrapper {
  background-color: white;
  padding: 0 16px;
}
.koala-query-form-wrapper .koala-query-form {
  padding: 16px 0 0;
  position: relative;
  border-bottom: 1px solid #f0f0f0;
}
.koala-query-form-wrapper .koala-query-form .form-item-container {
  display: flex;
  flex-wrap: wrap;
}
.koala-query-form-wrapper .koala-query-form .search-btnbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 16px;
}
.koala-query-form-wrapper .koala-query-form .search-btnbox .search-btn {
  background-color: #4273f6;
  color: white;
  border-color: #4273f6;
}
.koala-query-form-wrapper .koala-query-form .search-btnbox .search-btn:hover {
  background-color: #4273f6;
  color: #fff;
  border-color: #4273f6;
}
.koala-query-form-wrapper .koala-query-form .search-btnbox .collase-btn {
  cursor: pointer;
  margin: 0 10px;
  color: #4273f6;
}
